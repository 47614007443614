
import {
    computed, defineComponent, nextTick, onBeforeMount, onBeforeUnmount, onMounted, reactive, ref, watch,
} from 'vue';
import ShippingHeader from '@/modules/floortrak/view/shipping/components/ShippingHeader.vue';
import AddTransactionModal from '@/modules/floortrak/view/shipping/components/AddTransactionModal.vue';
import ShippingCloseoutModal from '@/modules/floortrak/view/shipping/components/ShippingCloseoutModal.vue';
import TransactionLineTable from '@/modules/floortrak/view/shared/components/TransactionLineTable.vue';
import TransactionItemEventBus from '@/modules/floortrak/view/shared/transaction-item-event-bus';
import TagListener from '@/modules/floortrak/services/TagListener';
import ShippingService from '@/modules/floortrak/services/ShippingService';
import { NotificationID, useNotification } from '@/composable/useNotifications';
import FloorTrakRouteTypes from '@/modules/floortrak/router/types';
import floorTrakStore from '@/modules/floortrak/store/FloorTrakStore';
import Shipment from '@/domain/Shipment';
import { ItemType } from '@/domain/enums/ItemType';
import Transaction from '@/domain/Transaction';
import TransactionLine from '@/domain/TransactionLine';
import ItemPicker from '@/components/ItemPicker.vue';
import Item from '@/domain/Item';
import router from '@/router';
import useDialogBox from '@/components/bootstrap-library/composables/useDialogBox';
import useValidator from '@/validation/useValidator';
import useLoading from '@/modules/floortrak/composables/useLoading';
import { CoreStore } from '@/store/CoreStore';
import TrackedItem from '@/domain/TrackedItem';
import TransactionService from '@/services/TransactionService';
import SupplierShipmentService from '@/services/SupplierShipmentService';
import FloortrakShippingTagScanHandler from '@/services/tag-scanning/scan-handler/FloortrakShippingTagScanHandler';
import useMaxIntCompute from '@/composable/useMaxIntCompute';
import SmartTrakFooter from '@/components/SmartTrakFooter.vue';
import { MaxTrailerProps, useMaxTrailerCompute } from '@/composable/useMaxTrailerCompute';
import { ItemTableData } from '@/domain/ItemTableData';
import TrailerType from '@/domain/TrailerType';
import BProgressBar from '@/components/bootstrap-library/BProgressBar.vue';
import BModal from '@/components/bootstrap-library/modal/BModal.vue';
import BButton from '@/components/bootstrap-library/BButton.vue';
import ShippingTruck from '@/modules/floortrak/view/shipping/components/ShippingTruck.vue';
import Screen from '@/components/layout/Screen.vue';
import BRow from '@/components/bootstrap-library/BRow.vue';
import BCol from '@/components/bootstrap-library/BCol.vue';
import { getTranslation, getTitleCaseTranslation } from '@/services/TranslationService';
import { FormValidationKey, ValidationResult } from '@/validation/types';
import SafetyInspectionQuestionnaire from '@/modules/floortrak/view/shared/components/SafetyInspectionQuestionnaire.vue';
import SafetyInspection from '@/domain/SafetyInspection';
import SafetyInspectionType from '@/domain/enums/SafetyInspectionType';
import SafetyInspectionResult from '@/domain/enums/SafetyInspectionResult';
import Carrier from '@/domain/Carrier';
import SafetyInspectionService from '@/services/SafetyInspectionService';

type State = {
    showAddItemModal: boolean;
    show: boolean;
    transactionNumberSearchValue: string;
    originalPostLoadInspectionPassed: boolean;
    preloadSafetyInspection: SafetyInspection;
    postloadSafetyInspection?: SafetyInspection;
    carriers: Carrier[];
    searchExistingSelected: boolean;
    showPreLoadSafetyInspection: boolean;
    showPostLoadSafetyInspection: boolean;
    showSafetyChecklist: boolean;
    showEquipmentInspection: boolean;
    trackedItems: Array<TrackedItem>;
    validationResult: ValidationResult<Shipment> | undefined;
    loading: boolean;
};

export default defineComponent({
    name: 'shipping-details',
    components: {
        BCol,
        BRow,
        Screen,
        ShippingTruck,
        BButton,
        BModal,
        BProgressBar,
        ItemPicker,
        ShippingHeader,
        TransactionLineTable,
        AddTransactionModal,
        ShippingCloseoutModal,
        SmartTrakFooter,
        SafetyInspectionQuestionnaire,
    },

    setup() {
        const shippingService = new ShippingService();
        const { shippingStore } = floorTrakStore.getInstance();
        const { carrierStore, profileStore } = CoreStore.getInstance();
        const loading = useLoading();
        const transactionService = new TransactionService();
        const supplierShipmentService = new SupplierShipmentService();
        const shippingModalRef = ref<InstanceType<typeof ShippingCloseoutModal>>();
        const addTransactionModalRef = ref<InstanceType<typeof AddTransactionModal>>();
        const { valueLessThanMax } = useMaxIntCompute();
        const saveOnlyValidationKey = 'ft-shipping-shipment-save-only';
        const moveOrShipValidationKey = 'ft-shipping-shipment';
        const safetyInspectionService = new SafetyInspectionService();
        const { forkliftCertified, certExpirationDate } = CoreStore.getInstance().profileStore;

        const originalShipmentStringified = ref<string>('');

        const isDirty = computed((): boolean => JSON.stringify(shippingStore.shipment) !== originalShipmentStringified.value);

        const { close, showError, showValidationError } = useNotification();

        const { confirm } = useDialogBox();

        const shippingHeaderTitle = computed(() => {
            if (shippingStore.isExisting) {
                // eslint-disable-next-line max-len
                return `${getTitleCaseTranslation('core.common.shipmentWithIdFrom', shippingStore.shipment.id ? shippingStore.shipment.id.toString() : '')} ${
                    shippingStore.activeTransaction.fromLocation.name
                }`;
            }
            return `${getTitleCaseTranslation('core.common.newShipmentFrom')} ${shippingStore.activeTransaction?.fromLocation.name || ''}`;
        });

        const state = reactive<State>({
            transactionNumberSearchValue: '',
            originalPostLoadInspectionPassed: false,
            preloadSafetyInspection: new SafetyInspection(),
            carriers: carrierStore.carriers,
            searchExistingSelected: false,
            showAddItemModal: false,
            show: false,
            showPreLoadSafetyInspection: false,
            showPostLoadSafetyInspection: false,
            showSafetyChecklist: false,
            showEquipmentInspection: true,
            trackedItems: [],
            validationResult: undefined,
            loading: false,
        });

        const trailerClosed = computed(() => (state.postloadSafetyInspection ? state.postloadSafetyInspection.result === SafetyInspectionResult.Passed : state.originalPostLoadInspectionPassed));

        const transactionLineErrors = computed(() => state.validationResult?.model.transactions?.[shippingStore.activeIndex]?.transactionLines);

        watch(
            () => shippingStore.shipment.transactions[0],
            (newValue: Transaction, oldValue: Transaction) => {
                // if you totally switch loads without unmounting/remounting screen
                if (newValue && oldValue && newValue.id !== oldValue.id && isDirty.value) {
                    state.showSafetyChecklist = true;
                }
            },
            { deep: true },
        );

        function addTag(trackedItem: TrackedItem) {
            if (trackedItem.deleted) {
                useNotification().showError(getTranslation('core.validation.barcodeDeleted'));
            }
            else {
                shippingStore.activeTransaction.addTrackedItem(trackedItem);
            }
        }

        async function myTagCallback(barcodes: Array<string>) {
            if (shippingStore.readonly) {
                useNotification().showWarning(getTranslation('core.validation.cannotAddMoreItemsToShipment'));
            } else {
                const tagsAlreadyScanned = shippingStore.transactionArray.map((transaction) => transaction.tagList).flat();
                const scanHandler = new FloortrakShippingTagScanHandler(shippingStore.activeTransaction, tagsAlreadyScanned, addTag);
                await scanHandler.performSearch(barcodes);
            }
        }

        const myTagListener = new TagListener(myTagCallback);

        onBeforeMount(async () => {
            if (!forkliftCertified) {
                await confirm({
                title: getTitleCaseTranslation('core.validation.forkliftCertificationHeader'),
                message: getTranslation('core.validation.forkliftCertificationMissing'),
                vHtml: true,
                });

                router.push({ name: FloorTrakRouteTypes.HOME });
            }

            else if(!certExpirationDate || new Date(certExpirationDate) < new Date(Date.now())) {
                await confirm({
                title: getTitleCaseTranslation('core.validation.forkliftCertificationHeader'),
                message: getTranslation('core.validation.forkliftCertificationExpired'),
                vHtml: true,
                });

                router.push({ name: FloorTrakRouteTypes.HOME });
            }
        });

        onMounted(async () => {
            // subscriptions must occur on mounted to ensure other components are fully destroyed and unsubscribed
            TransactionItemEventBus.$on('REMOVE_LINE', (line: TransactionLine) => {
                shippingStore.removeLine(line);
            });
            TransactionItemEventBus.$on('REMOVE_TAG', (tag: TrackedItem) => {
                shippingStore.activeTransaction.removeTrackedItem(tag);
            });
            myTagListener.startListener();
            window.onbeforeunload = () => {
                if (shippingStore.getIfContainersExistInTransactionArray()) {
                    return '';
                }
            };
        });

        onBeforeUnmount(() => {
            myTagListener.stopListener();
        });

        function shouldResumeShipment(): boolean {
            return shippingStore.getIfContainersExistInTransactionArray() && shippingStore.shipment.fromLocation.id === profileStore.userLocation.id;
        }

        async function initNewShipping() {
            if (shouldResumeShipment()) {
                const resumeTransaction = await confirm({
                    title: getTranslation('core.validation.youHaveAnUnfinishedTransaction'),
                    message: getTranslation('core.validation.resumeWhereLeftOff'),
                });

                if (resumeTransaction) {
                    state.show = true;
                } else {
                    shippingStore.resetTransactionArray();
                    shippingStore.addNewTransaction();
                    state.showSafetyChecklist = false;
                    state.showPreLoadSafetyInspection = true;
                    state.show = true;
                }
            } else {
                shippingStore.setDefaultFromLocation(profileStore.userLocation);
                shippingStore.resetTransactionArray();
                shippingStore.addNewTransaction();
                state.showSafetyChecklist = false;
                state.showPreLoadSafetyInspection = true;
                state.show = true;
            }
        }

        function routeContainsValidTransactionId(): boolean {
            return shippingStore.transactionArray.find((tr) => tr.transactionNumber === router.currentRoute.value.params.transactionNumber) !== null;
        }

        function transactionLoadedFromSearchPage(): boolean {
            return shippingStore.transactionArray.length > 0 && routeContainsValidTransactionId();
        }

        async function findTransactionByUrl() {
            const transactionNumber = parseInt(router.currentRoute.value.params.transactionNumber as string, 10);

            try {
                const response = await shippingService.lookupShipmentByTransactionNumberAndFromLocation(transactionNumber, profileStore.userLocation.id);
                if (response.success) {
                    shippingStore.resetTransactionArray();
                    shippingStore.shipment = new Shipment(response.shipment);
                    shippingStore.activeIndex = 0;
                    state.show = true;
                } else {
                    showError(getTranslation('core.validation.errorLookingUpShipment'));
                    await router.push({ name: FloorTrakRouteTypes.SHIPPING.SEARCH });
                }
            } catch (err) {
                await router.push({ name: FloorTrakRouteTypes.SHIPPING.SEARCH });
            }
        }

        async function initExistingShipping() {
            if (transactionLoadedFromSearchPage()) {
                shippingStore.shipment.fromLocation = shippingStore.activeTransaction.fromLocation;
                state.show = true;
            } else {
                await findTransactionByUrl();
                shippingStore.shipment.fromLocation = shippingStore.activeTransaction.fromLocation;
            }
            const preLoadInspectionExists = await safetyInspectionService.trailerInspectionExistsForShipment(shippingStore.shipment.id!);

            state.showSafetyChecklist = preLoadInspectionExists;
            state.showPreLoadSafetyInspection = !preLoadInspectionExists;

            if (preLoadInspectionExists) {
                state.originalPostLoadInspectionPassed = await safetyInspectionService.trailerInspectionOfTypePassesForShipment(shippingStore.shipment.id!, SafetyInspectionType.PostLoad);
            }
        }

        function initComponent() {
            switch (router.currentRoute.value.name) {
            case FloorTrakRouteTypes.SHIPPING.EXISTING:
                initExistingShipping();
                break;
            default:
                initNewShipping();
            }

            originalShipmentStringified.value = JSON.stringify(shippingStore.shipment);
        }

        onBeforeMount(() => {
            initComponent();
        });

        function resetComponent() {
            shippingStore.resetTransactionArray();
            state.show = false;
            nextTick(() => {
                initComponent();
            });
        }

        function showMissingFields(errors: string[]) {
            if (errors.length > 0) {
                showValidationError(errors, NotificationID.MISSING_FIELDS);
            }
        }

        function openAddItemModal() {
            close(NotificationID.MISSING_FIELDS);
            if (shippingStore.activeTransaction.toLocation && shippingStore.activeTransaction.toLocation.id && shippingStore.activeTransaction.toLocation.id > 0) {
                state.showAddItemModal = true;
            } else {
                showMissingFields([getTranslation('core.validation.toLocationMustBeSelected')]);
            }
        }

        function addItem(value: { item: Item; quantity: number }) {
            shippingStore.addItemManual(value.item, value.quantity);
            state.showAddItemModal = false;
        }

        function openAddTransactionModal() {
            addTransactionModalRef.value?.open();
        }

        function changeTransaction(index: number): void {
            shippingStore.activeIndex = index;
        }

        function getTransactionStopNumber(index: number): number {
            return index + 1;
        }

        function addNewTransaction(): void {
            const { validateForm, validationResult } = useValidator<Shipment>(moveOrShipValidationKey);
            validateForm(shippingStore.shipment);
            state.validationResult = validationResult;
            if (validationResult.isValid) {
                shippingStore.addNewTransaction();
            }
        }

        async function addExistingTransaction(transactionId: number) {
            if (valueLessThanMax(transactionId)) {
                const fromId = shippingStore.shipment.fromLocation.id;
                const transaction = await transactionService.getPickableTransactionForLoading(fromId, transactionId);
                if (transaction) {
                    shippingStore.addExistingTransaction(transaction);
                }
            } else {
                useNotification().showError(`${transactionId} ${getTranslation('core.validation.isNotValidTransactionId')}`);
            }
        }

        function validShipment(validationKey: FormValidationKey): boolean {
            const { validateForm, validationResult } = useValidator<Shipment>(validationKey);
            validateForm(shippingStore.shipment);
            state.validationResult = validationResult;
            return validationResult.isValid;
        }

        async function moveTransactionsToPicked(shipmentId: number): Promise<{ shipment?: Shipment }> {
            const { shipment } = await shippingService.moveTransactionsToPicked(shipmentId);
            return { shipment };
        }

        function closeShippingModal() {
            shippingModalRef.value?.close();
        }

        async function createNewShipment(validationKey: FormValidationKey): Promise<boolean> {
            let success = false;
            if (validShipment(validationKey)) {
                shippingStore.shipment.transactions.forEach((x) => {
                    x.shipDate = new Date();
                });

                const { shipmentId } = await shippingService.createNewPickedShipment(shippingStore.shipment);
                if (shipmentId) {
                    success = true;
                    shippingStore.shipment.id = shipmentId;
                    originalShipmentStringified.value = JSON.stringify(shippingStore.shipment);
                    safetyInspectionService.associateShipmentWithTrailerInspection(shipmentId, state.preloadSafetyInspection.id);

                    if (state.postloadSafetyInspection) {
                        safetyInspectionService.associateShipmentWithTrailerInspection(shipmentId, state.postloadSafetyInspection.id);
                    }
                } else {
                    loading.setLoading(false);
                    useNotification().showError(getTranslation('core.validation.unableToCreateNewShipment'));
                }
            }

            return success;
        }

        async function updateExistingShipment(validationKey: FormValidationKey): Promise<boolean> {
            let success = false;
            if (validShipment(validationKey)) {
                const { shipment } = await shippingService.updateExistingShipment(shippingStore.shipment);
                if (shipment) {
                    shippingStore.shipment = new Shipment(shipment);
                    originalShipmentStringified.value = JSON.stringify(shipment);
                    if (shippingStore.shipment.id) {
                        const { shipment: newShipment } = await moveTransactionsToPicked(shippingStore.shipment.id);
                        if (newShipment) {
                            shippingStore.shipment = new Shipment(newShipment);
                            originalShipmentStringified.value = JSON.stringify(newShipment);
                            useNotification().showSuccess(getTitleCaseTranslation('core.domain.shipmentSaved'));
                            success = true;
                        }
                    }
                } else {
                    useNotification().showError(`${getTranslation('core.validation.unableToSaveShipment')} ${shippingStore.shipment.id}`);
                }
            }
            return success;
        }

        const trailerType = computed((): TrailerType => new TrailerType(shippingStore.shipment.trailerType));

        const itemList = computed((): Array<ItemTableData> => ItemTableData.BuildShipmentTableData(shippingStore.shipment));

        const maxTrailerProps: MaxTrailerProps = {
            itemList,
            trailerType,
            itemQtyKey: 'actualQuantity',
        };

        const maxTrailerComposable = useMaxTrailerCompute(maxTrailerProps);

        async function save() {
            loading.setLoading(true);
            closeShippingModal();
            if (shippingStore.isExisting) {
                await updateExistingShipment(saveOnlyValidationKey);
            } else if (await createNewShipment(saveOnlyValidationKey)) {
                resetComponent();
            }
            loading.setLoading(false);
        }

        async function saveBeforeAction(): Promise<boolean> {
            let success = false;
            state.loading = true;
            if (isDirty.value || !shippingStore.shipment.id) {
                loading.setLoading(true);
                success = await (shippingStore.isExisting ? updateExistingShipment(moveOrShipValidationKey) : createNewShipment(moveOrShipValidationKey));
            } else if (validShipment(moveOrShipValidationKey) && shippingStore.shipment.id) {
                loading.setLoading(true);
                const { shipment } = await moveTransactionsToPicked(shippingStore.shipment.id);
                if (shipment) {
                    shippingStore.shipment = new Shipment(shipment);
                    success = true;
                }
                originalShipmentStringified.value = JSON.stringify(shippingStore.shipment);
            }

            state.loading = false;
            loading.setLoading(false);
            return success;
        }

        // await the download then close the modal after re-route when complete
        async function getShipmentReceipt(shipmentId: number) {
            shippingModalRef.value?.startDownload();
            await supplierShipmentService.getShipmentReceipt(shipmentId);
            closeShippingModal();

            shippingStore.resetTransactionArray();
            await router.push({ name: FloorTrakRouteTypes.SHIPPING.SEARCH });
        }

        async function moveShipmentToInTransit() {
            const shipmentId = shippingStore.shipment.id ?? 0;
            const response = await supplierShipmentService.shipNow(shipmentId);

            if (response) {
                await getShipmentReceipt(shipmentId);
            } else {
                closeShippingModal();
            }
        }

        async function moveTransactionsToHeld() {
            const shipmentId = shippingStore.shipment.id ?? 0;
            const transactionsMoved = await supplierShipmentService.moveTransactionsToHeld(shipmentId);

            if (transactionsMoved) {
                await getShipmentReceipt(shipmentId);
            } else {
                closeShippingModal();
            }
        }

        async function moveToYard() {
            loading.setLoading(true);
            await moveTransactionsToHeld();
            loading.setLoading(false);
        }

        async function shipNow() {
            loading.setLoading(true);
            await moveShipmentToInTransit();
            loading.setLoading(false);
        }

        function passedPreloadSafetyInspection(inspection: SafetyInspection) {
            state.preloadSafetyInspection = inspection;
        }

        async function resetPostloadSafetyInspection(inspection: SafetyInspection) {
            state.postloadSafetyInspection = inspection;
        }

        function cancelSafetyInspection() {
            router.push({ name: FloorTrakRouteTypes.HOME });
        }

        function closeTrailer() {
            state.showPostLoadSafetyInspection = true;
        }

        return {
            state,
            addNewTransaction,
            addExistingTransaction,
            openAddItemModal,
            openAddTransactionModal,
            changeTransaction,
            getTransactionStopNumber,
            addItem,
            shippingStore,
            ItemType,
            shippingHeaderTitle,
            transactionLineErrors,
            shipNow,
            save,
            moveToYard,
            shippingModalRef,
            addTransactionModalRef,
            maxTrailerComposable,
            getTitleCaseTranslation,
            SafetyInspectionType,
            passedPreloadSafetyInspection,
            cancelSafetyInspection,
            closeTrailer,
            resetPostloadSafetyInspection,
            trailerClosed,
            saveBeforeAction,
        };
    },
});
